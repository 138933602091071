<template>
    <v-dialog
        @click:outside="closeModal"
        v-model="dialog"
        transition="dialog-bottom-transition"
        width="90%"
        max-width="900px"
        overlay-color="black"
        content-class="rounded-xl elevation-0"
    >

    <v-card
        color="white"
        class="pa-9 d-flex flex-column align-items-center justify-content-center text-center"
        :loading="loadingData"
    >
            <h1 class="my-3">
                {{ $t('diagnoses.titles.diagnosesInformation') }}
            </h1>

            <v-col cols="12">
                <v-row class="d-flex align-center justify-start my-4">
                    <p class="ma-0 pa-0 font-weight-regular body-1">
                        {{ $t('diagnoses.tables.evaluationsList.labels.section') }}:
                    </p>
                    <v-chip label color="deep-purple lighten-5"  class="mx-2">
                        <v-icon color="primary" small class="mr-2" >
                            mdi-folder
                        </v-icon>
                        <span class="primary--text body-2 font-weight-bold">
                            {{ diagnosis.section }}
                        </span>
                    </v-chip>
                </v-row>

                <v-row class="d-flex align-center justify-start my-4">
                    <p class="ma-0 pa-0 font-weight-regular body-1">
                        {{ $t('diagnoses.utils.totalOfQuestions') }}:
                    </p>
                    <v-chip label color="deep-purple lighten-5"  class="mx-2">
                        <v-icon color="primary" small class="mr-2" >
                            mdi-help-circle
                        </v-icon>
                        <span class="primary--text body-2 font-weight-bold">
                            {{ totalQuestions }}
                        </span>
                    </v-chip>
                </v-row>

                <v-row class="d-flex align-center justify-start my-4">
                    <p class="ma-0 pa-0 font-weight-regular body-1">
                        {{ $t('diagnoses.utils.diagnosisType') }}:
                    </p>
                    <v-chip label color="deep-purple lighten-5"  class="mx-2">
                        <v-icon color="primary" small class="mr-2" >
                            mdi-file-document-edit
                        </v-icon>
                        <span class="primary--text body-2 font-weight-bold">
                            {{ evaluationTypes.find(ev => ev.id === diagnosis.type)?.name }}
                        </span>
                    </v-chip>
                </v-row>

                <v-row class="d-flex flex-column align-start justify-start my-4 diagnosis-text--modal">
                    <h2 class="font-regular body-1 my-2 text-left">{{ $t('diagnoses.utils.text') }} {{showText+1}}: <span class="font-weight-bold">{{ currentText?.name }}</span></h2>
                    <p class="text-start body-2 diagnosis-text--modal" v-html="currentText?.content?.contenido"></p>
                </v-row>
            </v-col>

            <DiagnosisInformationTable
                :headers="textTableHeaders"
                :items="textTableItems"
            />

            <v-row class="my-3" align-content="center" justify="space-between">
                <v-btn
                    elevation="0"
                    class="mx-3"
                    color="primary"
                    @click="prevText"
                    :disabled="!isAbleToPrevText"
                    text
                >
                    <span class="text-center text-lowercase first-letter-uppercase body-1 font-weight-medium">
                        {{ $t('diagnoses.actions.diagnosis.prevText') }}
                    </span>
                </v-btn>
                <v-btn
                    elevation="0"
                    class="mx-3"
                    color="primary"
                    @click="nextText"
                    :disabled="!isAbleToNextText"
                    text
                >
                    <span class="text-center text-lowercase first-letter-uppercase body-1 font-weight-medium">
                        {{ $t('diagnoses.actions.diagnosis.nextText') }}
                    </span>
                </v-btn>

            </v-row>

            <!-- Action buttons -->
            <v-row class="my-3" align-content="center" justify="center">
                <v-btn
                    elevation="0"
                    class="mx-3"
                    large
                    outlined
                    color="primary"
                    @click="closeModal"
                    :disabled="loadingData"
                >
                    <span class="text-center text-capitalize body-1 font-weight-medium">
                        {{ $t('assignments.actions.cancel') }}
                    </span>
                </v-btn>

            </v-row>
        </v-card>

    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import DiagnosisInformationTable from '../tables/diagnosisInformation/index.vue'

export default {
    name: 'DiagnosesInformationModal',
    props: {
        dialog: {
            type: Boolean,
            default: false,
            required: true
        },
        currentDiagnosis: {
            type: Object,
            default: () => {},
            required: false
        }
    },
    components: {
        DiagnosisInformationTable
    },
    data() {
        return {
            loadingData: false,
            diagnosis: this.currentDiagnosis,
            showText: 0,
        }
    },
    computed: {
        ...mapState({
            teacherId: (state) => state.userData.user.id,
        }),
        evaluationTypes() {
            return [
                {
                    id: 'INITIAL',
                    name: this.$t('diagnoses.diagnosis.types.initial')
                },
                {
                    id: 'INTERMEDIATE',
                    name: this.$t('diagnoses.diagnosis.types.middle')
                },
                {
                    id: 'ADVANCED',
                    name: this.$t('diagnoses.diagnosis.types.final')
                },
            ]
        },
        totalQuestions() {
            return this.diagnosis.texts?.reduce((acc, text) => acc + text?.questions.length, 0)
        },
        currentText() {
            return this.diagnosis.texts[this.showText]
        },
        isAbleToNextText() {
            return this.showText < this.diagnosis.texts.length - 1
        },
        isAbleToPrevText() {
            return this.showText > 0
        },
        textTableHeaders() {
            return [
                // {
                //     text: 'Desempeño',
                //     value: 'performance',
                //     align: 'start',
                //     sortable: false,
                // },
                {
                    text: this.$t('diagnoses.utils.question'),
                    value: 'question',
                    align: 'start',
                    sortable: false,
                },
                {
                    text: 'R1',
                    value: 'r1',
                    align: 'start',
                    sortable: false,
                },
                {
                    text: 'R2',
                    value: 'r2',
                    align: 'start',
                    sortable: false,
                },
                {
                    text: 'R3',
                    value: 'r3',
                    align: 'start',
                    sortable: false,
                },
                {
                    text: 'R4',
                    value: 'r4',
                    align: 'start',
                    sortable: false,
                },
            ]
        },
        textTableItems() {
            if (!this.currentText?.questions) return []
            return this.currentText?.questions.map((question) => ({
                question: question.contenido,
                answers: question.respuestas,
                r1: question.respuestas[0],
                r2: question.respuestas[1],
                r3: question.respuestas[2],
                r4: question.respuestas[3],
                correctAnswer: question.respuestaCorrecta
            }))
        }
    },
    methods: {
        closeModal(updateList) {
            this.$emit('close-modal', updateList)
        },
        nextText() {
            if (this.isAbleToNextText) {
                this.showText++
            }
        },
        prevText() {
            if (this.isAbleToPrevText) {
                this.showText--
            }
        }
    },
}
</script>

<style lang="scss">
.diagnosis-text--modal{
    img {
        // display: none !important;
        max-width: 100%;
    }
    max-width: 100%;
}
</style>
