<template>
    <v-data-table
        :headers="headers"
        :items="loading ? [] : items"
        :items-per-page="itemsPerPage"
        :hide-default-footer="hideDefaultFooter"
        :sort-by="sortByHeaders"
        :sort-desc="sortByDesc"
        class="elevation-0 reports__table"
        :loading="loading"
        height="100%"
    >
        <template v-slot:item.section="{ item }">
            <v-chip label color="deep-purple lighten-5" class="px-9 my-3">
                <v-row align-content="center" justify="start">
                    <v-icon color="deep-purple" class="mr-2" small> mdi-folder </v-icon>
                    <span
                        class="body-2 deep-purple--text lighten-5 text-capitalize font-weight-medium"
                    >
                        {{ item.gradeFrom }}°
                        <span class="text-lowercase">{{ $t('diagnoses.utils.to') }}</span>
                        {{ item.gradeTo }}° <span class="text-lowercase">{{ $t('diagnoses.utils.grade') }}</span>
                    </span>
                </v-row>
            </v-chip>
        </template>

        <template v-slot:item.initial="{ item }">
            <v-chip
                small
                color="orange
                lighten-4"
                class="pa-3 evaluation-option"
                @click="viewDiagnostic(item, 'INITIAL')"
            >
                <v-icon color="brown" small class="mr-2"> mdi-file-document </v-icon>
                <span
                    class="body-2 brown--text text-decoration-underline text-capitalize font-weight-bold"
                >
                    {{ $t('diagnoses.actions.view') }}
                </span>
            </v-chip>
        </template>
        <template v-slot:item.middle="{ item }">
            <v-chip
                small
                color="orange
                lighten-4"
                class="evaluation-option"
                @click="viewDiagnostic(item, 'INTERMEDIATE')"
            >
                <v-icon color="brown" small class="mr-2"> mdi-file-document </v-icon>
                <span
                    class="body-2 brown--text text-decoration-underline text-capitalize font-weight-bold"
                >
                    {{ $t('diagnoses.actions.view') }}
                </span>
            </v-chip>
        </template>
        <template v-slot:item.final="{ item }">
            <v-chip
                small
                color="orange
                lighten-4"
                class="evaluation-option"
                @click="viewDiagnostic(item, 'ADVANCED')"
            >
                <v-icon color="brown" small class="mr-2"> mdi-file-document </v-icon>
                <span
                    class="body-2 brown--text text-decoration-underline text-capitalize font-weight-bold"
                >
                    {{ $t('diagnoses.actions.view') }}
                </span>
            </v-chip>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: 'DiagnosesTable',
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        items: {
            type: Array,
            default: () => [],
            required: false,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
            required: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: true,
            required: false,
        },
        sortByHeaders: {
            type: Array,
            default: () => [],
            required: false,
        },
        sortByDesc: {
            type: Boolean,
            default: false,
            required: false,
        },
        headers: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    methods: {
        viewDiagnostic(diagnosticRange, diagnosticType) {
            this.$emit('view-diagnostic', { diagnosticRange, diagnosticType })
        },
    },
}
</script>

<style scope lang="scss">
@import '../../../../../assets/style/colors.scss';

.headers-table {
    background: $purple-primary !important;
}

.reports__table .v-data-table-header {
    background-color: $purple-primary;
}
.reports__table .v-data-table-header .sortable.active {
    background-color: #d7d2ef;
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: none !important;
}
.evaluation-option {
    cursor: pointer;
}

.text-no-wrap {
    word-break: keep-all;
}
</style>
