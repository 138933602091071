import {
  Diagnostic,
} from "@/store/modules/Diagnoses/types";
import Services from "../base-services";
import mapParameters from "@/utils/map-parameters";

const service = new Services('mdDiagnostico')

export const diagnosticsService = {
    async getDiagnoses(gradeFrom: number, gradeTo: number, level: string, withInfo = false) {
        const params = mapParameters({
            gradeFrom: gradeFrom ?? 1,
            gradeTo: gradeTo ?? 12,
            level,
            withInfo,
        })
        const data = await service.get('getDiagnostics', params)
        return data.content as Diagnostic[]
    },
    async getDiagnostic(diagnosticId: number) {
        const params = mapParameters({
            diagnosticId,
        })
        const data = await service.get('getDiagnostic', params)
        return data.content as Diagnostic
    }
}
