<template>
    <v-data-table
      :headers="headers"
      :items="loading ? [] : items"
      :items-per-page="itemsPerPage"
      :hide-default-footer="hideDefaultFooter"
      :sort-by="sortByHeaders"
      :sort-desc="sortByDesc"
      class="elevation-0 reports__table"
      :loading="loading"
      height="100%"
      :hide-default-header="true"
    >

    <template v-slot:header="{ props }">
      <thead class="v-data-table-header">
        <tr>
          <th v-for="(header, index) in props.headers" :key="index">
            <v-row :class="{ 'text-center': index !== 0 }">
              <v-col class="align-center">{{ header.text }}</v-col>
            </v-row>
          </th>
        </tr>
      </thead>
    </template>

        <template v-slot:item.question="{ item }">
            <v-row class="d-flex w-100 my-4">
                <v-col class="py-0">{{ item.question }}</v-col>
            </v-row>
        </template>
        <template v-slot:item.r1="{ item }">
            <v-row class="d-flex h-100 py-1 my-1" elevation="0">
                <v-col><span :class="item.correctAnswer === item.r1.item ? 'teal lighten-5 black--text' : ''">{{ item.r1.contenido }}</span></v-col>
            </v-row>
        </template>
        <template v-slot:item.r2="{ item }">
            <v-row class="d-flex h-100 py-1 my-1" elevation="0">
                <v-col><span :class="item.correctAnswer === item.r2.item ? 'teal lighten-5 black--text' : ''">{{ item.r2.contenido }}</span></v-col>
            </v-row>
        </template>
        <template v-slot:item.r3="{ item }">
            <v-row class="d-flex h-100 py-1 my-1" elevation="0">
                <v-col><span :class="item.correctAnswer === item.r3.item ? 'teal lighten-5 black--text' : ''">{{ item.r3.contenido }}</span></v-col>
            </v-row>
        </template>
        <template v-slot:item.r4="{ item }">
            <v-row class="d-flex h-100 py-1 my-1" elevation="0">
                <v-col><span :class="item.correctAnswer === item.r4.item ? 'teal lighten-5 black--text' : ''">{{ item.r4.contenido }}</span></v-col>
            </v-row>
        </template>
        
    </v-data-table>
</template>

<script>
export default {
    name: 'DiagnosesTable',
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        items: {
            type: Array,
            default: () => [],
            required: false,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
            required: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: true,
            required: false,
        },
        sortByHeaders: {
            type: Array,
            default: () => [],
            required: false,
        },
        sortByDesc: {
            type: Boolean,
            default: false,
            required: false,
        },
        headers: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
   
}
</script>

<style scope lang="scss">
@import '../../../../../assets/style/colors.scss';

.headers-table {
  background: $purple-primary !important;
}

.reports__table .v-data-table-header {
  background-color: $purple-primary;
}
.reports__table .v-data-table-header .sortable.active {
  background-color: #D7D2EF;
}
.reports__table .v-data-table__wrapper > table {
   & > tbody > tr > td {
     padding: 0 12px;
   }
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.evaluation-option {
  cursor: pointer;
}

.text-no-wrap {
  word-break: keep-all;
}

</style>
