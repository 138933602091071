<template>
    <v-container class="ma-0 pa-0">
        <v-card color="white" elevation="0" class="pa-3 py-5 d-inline-flex" rounded="lg">
            <SimpleButton
                data-cy="assign-evaluation-btn"
                icon="mdi-clipboard-check-multiple"
                :text="$t('diagnoses.actions.evaluation.assign')"
                @click="handleAssignEvaluation"
            />
        </v-card>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">
                {{ $t('diagnoses.utils.instructions') }}
            </h2>
            <v-divider></v-divider>

            <section class="pa-6">
                <div class="mb-5">
                    <AlertBannerCard
                        icon="mdi-information"
                        :text="$t('diagnoses.texts.evaluations.information')"
                    />
                </div>

                <article>
                    <DiagnosesTable
                        :headers="diagnosticsHeaders"
                        :items="generateEvaluationItems"
                        :loading="loading"
                        @view-diagnostic="onViewDiagnostic"
                    />
                </article>
            </section>
        </v-card>

        <AssignEvaluationModal 
            :dialog="showEvaluationModal"
            @close-modal="closeEvaluationModal"
        />

        <SuccessEvaluationAssignedModal
            :dialog="showSuccesfulEvaluationAssigned"
            @go-back="goBackToEvaluationsAssigned"
            @see-evaluations-assigned="handleGoToEvaluations"
        />

        <DiagnosesInformationModal
            v-if="showDiagnosesInformationModal"
            :dialog="showDiagnosesInformationModal"
            :currentDiagnosis="currentDiagnosis"
            @close-modal="closeDiagnosesInformationModal"
        />
    </v-container>
</template>

<script>
import SimpleButton from '@/components/core/buttons/simpleButton/index.vue'
import AssignEvaluationModal from '@/components/diagnoses/shared/modals/assignEvaluation.vue'
import SuccessEvaluationAssignedModal from '@/components/diagnoses/shared/modals/successfulEvaluationAssigned.vue'
import DiagnosesInformationModal from '@/components/diagnoses/shared/modals/diagnosesInformation.vue'
import AlertBannerCard from '@/components/core/alerts/banner/index.vue'
import DiagnosesTable from '@/components/diagnoses/shared/tables/diagnoses/index.vue'
import { diagnosticsService } from '@/services/evaluations/diagnostics'

export default {
    name: 'EvaluationsList',
    components: {
        SimpleButton,
        AssignEvaluationModal,
        SuccessEvaluationAssignedModal,
        AlertBannerCard,
        DiagnosesTable,
        DiagnosesInformationModal,
    },
    data() {
        return {
            showEvaluationModal: false,
            showSuccesfulEvaluationAssigned: false,
            showDiagnosesInformationModal: false,
            loading: false,
            currentDiagnosisInformation: undefined,
            diagnostics: [],
        }
    },
    computed: {
        diagnosticsHeaders() {
            return [
                {
                    text: this.$t('diagnoses.tables.evaluationsList.labels.section'),
                    align: 'start',
                    sortable: false,
                    value: 'section',
                },
                {
                    text: this.$t('diagnoses.tables.evaluationsList.labels.initial'),
                    align: 'center',
                    sortable: false,
                    value: 'initial',
                },
                {
                    text: this.$t('diagnoses.tables.evaluationsList.labels.middle'),
                    align: 'center',
                    sortable: false,
                    value: 'middle',
                },
                {
                    text: this.$t('diagnoses.tables.evaluationsList.labels.final'),
                    align: 'center',
                    sortable: false,
                    value: 'final',
                },
            ]
        },
        generateEvaluationItems() {
            const gradeRangeAndLevel = this.diagnostics
                .map(({ gradeFrom, gradeTo, diagnosticLevel }) => ({
                    gradeFrom,
                    gradeTo,
                    diagnosticLevel,
                }))
                .reduce((accumulator, currentValue) => {
                    const { gradeFrom, gradeTo, diagnosticLevel } = currentValue
                    const key = `${gradeFrom}-${gradeTo}`

                    if (!accumulator[key]) {
                        // eslint-disable-next-line no-param-reassign
                        accumulator[key] = []
                    }
                    if (!accumulator[key].includes(diagnosticLevel)) {
                        accumulator[key].push(diagnosticLevel)
                    }
                    return accumulator
                }, {})

            const groupedByGradeRange = Object.entries(gradeRangeAndLevel).map(([key, value]) => ({
                section: this.$t(`diagnoses.coursesList.${key}`),
                initial: value.includes('INITIAL') ? 'INITIAL' : null,
                middle: value.includes('INTERMEDIATE') ? 'INTERMEDIATE' : null,
                final: value.includes('ADVANCED') ? 'ADVANCED' : null,
                gradeFrom: Number(key.split('-')[0]),
                gradeTo: Number(key.split('-')[1]),
            }))
            return groupedByGradeRange
        },
        currentDiagnosis() {
            return this.currentDiagnosisInformation
        },
    },
    methods: {
        handleAssignEvaluation() {
            this.showEvaluationModal = true
            this.showDiagnosesInformationModal = false
        },
        closeEvaluationModal() {
            this.showEvaluationModal = false
        },
        handleGoToEvaluations() {
            this.showSuccesfulEvaluationAssigned = false
        },
        goBackToEvaluationsAssigned() {
            this.showSuccesfulEvaluationAssigned = false
        },
        async getDiagnostics() {
            this.diagnostics = await diagnosticsService.getDiagnoses()
            return this.diagnostics
        },
        async onViewDiagnostic(diagnosis) {
            const texts = await this.getDiagnosesTextsInformation(
                diagnosis.diagnosticRange.gradeFrom,
                diagnosis.diagnosticRange.gradeTo,
                diagnosis.diagnosticType
            )
            this.currentDiagnosisInformation = {
                texts,
                section: diagnosis.diagnosticRange.section,
                type: diagnosis.diagnosticType,
            }
            this.showDiagnosesInformationModal = true
        },
        closeDiagnosesInformationModal() {
            this.showDiagnosesInformationModal = false
        },
        async getDiagnosesTextsInformation(from, to, level) {
            this.loading = true
            const diagnostics = await diagnosticsService.getDiagnoses(from, to, level, true)
            const texts = diagnostics.filter(
                ({
                    gradeFrom,
                    gradeTo,
                    diagnosticLevel,
                }) => gradeFrom === from && gradeTo === to && diagnosticLevel === level
            )
            const uniqueTexts = texts.reduce(
                (acc, current) => {
                    if (!acc.map[current.id]) {
                        acc.map[current.id] = true // Mark this id as seen
                        acc.result.push(current) // Add the object to the result array
                    }
                    return acc
                },
                { map: {}, result: [] }
            )
            this.loading = false
            return uniqueTexts.result
        },
    },
    async beforeMount() {
        this.loading = true
        await this.getDiagnostics()
        this.loading = false
    },
}
</script>
